<template>
  <div class="stat-details">
    <v-row align="center">
      <v-col cols="12">
        <BarChart
          :chartdata="chartData"
          :options="chartOptions"
          :height="250"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import BarChart from './BarChart';

export default {
  name: 'TrendChart',
  components: {
    BarChart,
  },
  props: [
    'title',
    'label',
    'dates',
    'values',
    'rollingAverages',
  ],
  data() {
    return {
      chartStyles: {
        height: '200px',
        position: 'relative',
      },
      chartOptions: {
        responsive: true,
        title: {
          text: this.title,
          display: true,
        },
      },
    };
  },
  computed: {
    chartLabels() {
      return this.dates.map((date) => moment(date).format('MMM D'));
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            type: 'line',
            label: '7-Day Avg',
            pointBackgroundColor: 'rgba(96, 125, 139, 1)',
            borderColor: 'rgba(96, 125, 139, 1)',
            pointBorderColor: 'rgba(96, 125, 139, 1)',
            data: this.rollingAverages,
          },
          {
            type: 'bar',
            label: `New ${this.label}`,
            backgroundColor: 'rgba(0, 150, 136, 1)',
            pointBackgroundColor: 'rgba(0, 150, 136, 1)',
            borderColor: 'rgba(0, 150, 136, 1)',
            pointBorderColor: 'rgba(0, 150, 136, 1)',
            data: this.values,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .chart {
    position: relative;
    height: 200px;
    margin-bottom: 20px;
    cursor: crosshair;
  }
</style>
