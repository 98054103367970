<template>
  <div class="country">
    <div class="loader" v-if="!loaded">
      <v-progress-linear indeterminate />
      Loading data for the United States...
    </div>
    <v-row v-else align="center">
      <v-col cols="12" class="d-none d-sm-flex">
        <h1 class="display-2 mt-2 mb-4">United States of America</h1>
      </v-col>
      <v-col cols="12" md="6">
        <div class="caption">Last Updated: {{ date | moment('MMM, D') }}</div>
        <LineChartWrapper
          v-if="loaded"
          title="Total Cases and Deaths"
          :cases="filteredCases"
          :deaths="filteredDeaths"
          :dates="filteredDates"
          :population="population"
          :loaded="loaded"
        />
      </v-col>
      <v-col cols="12" md="6">
        <BarChartWrapper
          v-if="loaded"
          title="Amount of Change from Previous Day"
          :cases="filteredCaseDiffs"
          :deaths="filteredDeathDiffs"
          :dates="filteredDateDiffs"
          :loaded="loaded"
        />
      </v-col>
      <v-divider></v-divider>
    </v-row>
    <ReportList :reports="orderedStates"></ReportList>
  </div>
</template>

<script>
import { get } from 'axios';
import { mapMutations } from 'vuex';
import LineChartWrapper from '../components/LineChartWrapper.vue';
import BarChartWrapper from '../components/BarChartWrapper.vue';
import ReportList from '../components/ReportList.vue';

export default {
  name: 'CountryView',
  data() {
    return {
      startIndex: null,
      endIndex: null,
      loaded: false,
      cases: null,
      deaths: null,
      date: null,
      population: null,
      history: null,
      differences: null,
      states: null,
    };
  },
  components: {
    LineChartWrapper,
    BarChartWrapper,
    ReportList,
  },
  computed: {
    orderedStates() {
      if (!this.states) return null;
      return [...this.states].sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    filteredCases() { return this.filteredItems(this.history.cases); },
    filteredDeaths() { return this.filteredItems(this.history.deaths); },
    filteredDates() { return this.filteredItems(this.history.dates); },
    filteredCaseDiffs() { return this.filteredItems(this.differences.cases); },
    filteredDeathDiffs() { return this.filteredItems(this.differences.deaths); },
    filteredDateDiffs() { return this.filteredItems(this.differences.dates); },
  },
  methods: {
    ...mapMutations([
      'updateTitle',
      'updateBackUrl',
    ]),
    filteredItems(items) {
      return items.filter(
        (day, index) => (index >= this.startIndex && index <= this.endIndex),
      );
    },
  },
  async mounted() {
    const { data } = await get('/api/us/index.json');

    this.cases = data.cases;
    this.deaths = data.deaths;
    this.date = data.date;
    this.population = data.population;
    this.history = data.history;
    this.differences = data.differences;
    this.states = data.states;

    this.updateTitle('United States');
    this.updateBackUrl(null);
    this.endIndex = this.history && this.history.cases && this.history.cases.length - 1;
    this.startIndex = this.endIndex > 13 ? this.endIndex - 14 : 0;
    this.loaded = true;
  },
};
</script>

<style>
.state:hover {
  cursor: pointer;
}
</style>
