<template>
  <div>
    <div class="display-1">{{ perThousand | numeric('0,0.00') }}</div>
    <div class="font-weight-light caption text-uppercase">
      {{label}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopulationStats',
  props: {
    items: {
      type: Number,
    },
    population: {
      type: Number,
    },
    label: {
      type: String,
    },
  },
  computed: {
    perThousand() {
      if (!this.items) return null;
      return (1.0 * this.items) / (this.population / 1000);
    },
  },
};
</script>

<style>

</style>
