<template>
  <v-chip
    class="ma-2"
    :color="difference.chipColor"
    text-color="white"
  >
    <v-avatar left>
      <v-icon>{{difference.icon}}</v-icon>
    </v-avatar>
    {{difference.pct}}%
  </v-chip>
</template>

<script>
import { mdiArrowUp, mdiArrowDown } from '@mdi/js';

export default {
  name: 'ChangeChip',
  props: {
    before: {
      type: Number,
      default: 0,
    },
    after: {
      type: Number,
      required: true,
    },
  },
  computed: {
    difference() {
      const difference = this.after - this.before;
      const pct = Math.floor((difference / this.before) * 100) || 0;
      return {
        raw: difference,
        pct,
        icon: this.icon(pct),
        chipColor: this.chipColor(pct),
      };
    },
  },
  methods: {
    chipColor(pct) {
      const modifier = Math.floor(pct / 20);
      let color;
      if (pct > 0) color = 'red';
      if (pct === 0) color = 'gray';
      if (pct < 0) color = 'green';
      return `${color} darken-${modifier}`;
    },
    icon(pct) {
      if (pct > 0) return mdiArrowUp;
      if (pct === 0) return '';
      return mdiArrowDown;
    },
  },
};
</script>

<style>

</style>
