import Vue from 'vue';
import TrendChart from 'vue-trend-chart';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import uppercase from './filters/uppercase';
import numeric from './filters/numeric';
import titleize from './filters/titleize';
import unUrl from './filters/unUrl';
import plusMinus from './filters/plusMinus';

Vue.filter('uppercase', uppercase);
Vue.filter('numeric', numeric);
Vue.filter('titleize', titleize);
Vue.filter('unUrl', unUrl);
Vue.filter('plusMinus', plusMinus);

Vue.use(TrendChart);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
