import Vue from 'vue';
import VueRouter from 'vue-router';
import StateView from '../views/StateView.vue';
import CountryView from '../views/CountryView.vue';
import CountyView from '../views/CountyView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:state/:county',
    name: 'CountyView',
    component: CountyView,
    props: true,
  },
  {
    path: '/:state',
    name: 'StateView',
    props: true,
    component: StateView,
  },
  {
    path: '/',
    name: 'CountryView',
    component: CountryView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'COVIDbyCounty.org';
  next();
});
export default router;
