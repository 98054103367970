import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: 'COVID by County',
    backUrl: null,
    loaded: false,
  },
  mutations: {
    updateTitle(state, title) {
      state.title = title;
    },
    updateBackUrl(state, url) {
      state.backUrl = url;
    },
    startLoading(state) {
      state.loaded = false;
    },
    finishLoading(state) {
      state.loaded = true;
    },
  },
  actions: {
  },
  modules: {
  },
});
