<template>
  <div class="county">
    <div class="loader" v-if="!loaded">
      <v-progress-linear indeterminate />
      Loading data for {{ county | unUrl | titleize }}, {{ state | unUrl | titleize }}...
    </div>
    <v-row v-else justify="center" align="start">
      <v-col cols="12" class="d-none d-sm-flex">
        <h1 class="display-2 mt-2 mb-4">
          {{county | unUrl | titleize}}, {{state | unUrl | titleize}}
        </h1>
      </v-col>
      <v-col cols="12" md="6">
        <TrendChart
          v-if="loaded"
          title="New Cases"
          label="Cases"
          :values="newCases"
          :rollingAverages="caseRollingAverages"
          :dates="dates" />
        <v-row>
          <v-col cols="4" class="text-center">
            <Stat
              v-if="loaded"
              :value="cases"
              label="cases"
              :before="weekAgoReport.cases.total"
              :after="cases"
            />
          </v-col>
          <v-col cols="4" class="text-center">
            <Stat
              v-if="loaded"
              :value="currentReport.cases.rollingAverage.toFixed(2)"
              label="7-day average"
              :before="weekAgoReport.cases.rollingAverage"
              :after="currentReport.cases.rollingAverage"
            />
          </v-col>
          <v-col cols="4" class="text-center">
            <Stat
              v-if="loaded"
              :value="casesPerThousand.toFixed(2)"
              label="/ 1k people"
              :before="weekAgoCasesPerThousand"
              :after="casesPerThousand"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <TrendChart
          v-if="loaded"
          title="New Deaths"
          label="Deaths"
          :values="newDeaths"
          :rollingAverages="deathRollingAverages"
          :dates="dates" />
        <v-row>
          <v-col cols="4" class="text-center">
            <Stat
              v-if="loaded"
              :value="deaths"
              label="deaths"
              :before="weekAgoReport.deaths.total"
              :after="deaths"
            />
          </v-col>
          <v-col cols="4" class="text-center">
            <Stat
              v-if="loaded"
              :value="currentReport.deaths.rollingAverage.toFixed(2)"
              label="7-day average"
              :before="weekAgoReport.deaths.rollingAverage"
              :after="currentReport.deaths.rollingAverage"
            />
          </v-col>
          <v-col cols="4" class="text-center">
            <Stat
              v-if="loaded"
              :value="deathsPerThousand.toFixed(2)"
              label="/ 1k people"
              :before="weekAgoDeathsPerThousand"
              :after="deathsPerThousand"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get } from 'axios';
import { mapMutations } from 'vuex';
import TrendChart from '../components/TrendChart.vue';
import Stat from '../components/Stat.vue';

export default {
  name: 'CountyView',
  components: {
    TrendChart,
    Stat,
  },
  props: {
    state: {
      type: String,
      required: true,
    },
    county: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      name: null,
      fips: null,
      population: null,
      reports: null,
      chartOptions: {
        responsive: true,
      },
    };
  },
  computed: {
    filteredReports() {
      return this.reports.slice(this.reports.length - 14, this.reports.length + 1);
    },
    currentReport() { return this.filteredReports[this.filteredReports.length - 1]; },
    weekAgoReport() { return this.filteredReports[this.filteredReports.length - 8]; },
    cases() { return this.currentReport.cases.total; },
    deaths() { return this.currentReport.deaths.total; },
    date() { return this.currentReport.date; },
    casesPerThousand() { return this.perCapita(this.currentReport.cases.total); },
    deathsPerThousand() { return this.perCapita(this.currentReport.deaths.total); },
    weekAgoCasesPerThousand() { return this.perCapita(this.weekAgoReport.cases.total); },
    weekAgoDeathsPerThousand() { return this.perCapita(this.weekAgoReport.deaths.total); },
    newCases() {
      return this.filteredReports.map((report) => report.cases.diff);
    },
    caseRollingAverages() {
      return this.filteredReports.map((report) => report.cases.rollingAverage);
    },
    newDeaths() {
      return this.filteredReports.map((report) => report.deaths.diff);
    },
    deathRollingAverages() {
      return this.filteredReports.map((report) => report.deaths.rollingAverage);
    },
    filteredDeaths() {
      return this.filteredReports.map((report) => report.deaths);
    },
    dates() {
      return this.filteredReports.map((report) => report.date);
    },
  },
  methods: {
    perCapita(value) { return ((1.0 * value) / (this.population / 1000)); },
    async getCounty() {
      const filePath = `/api/us/${this.urlify(this.state)}/${this.urlify(this.county)}.json`;

      const { data } = await get(filePath);
      const {
        name,
        fips,
        population,
        reports,
      } = data;

      this.name = name;
      this.fips = fips;
      this.population = population;
      this.reports = reports;

      this.loaded = true;
    },
    urlify(str) { return str.replace(/[^a-z0-9]/gi, '_').toLowerCase(); },
    ...mapMutations([
      'updateTitle',
      'updateBackUrl',
    ]),
  },
  async mounted() {
    await this.getCounty();

    this.updateTitle(this.county);
    this.updateBackUrl(`/${this.urlify(this.state)}`);
  },
};
</script>

<style>

</style>
