<template>
  <div class="state">
    <div class="loader" v-if="!loaded">
      <v-progress-linear indeterminate />
      Loading data for {{ state | unUrl | titleize }}...
    </div>
    <v-row justify="center" align="start" v-else>
      <v-col cols="12" class="d-none d-sm-flex">
        <h1 class="display-2 mt-2 mb-4">{{state | unUrl | titleize}}</h1>
      </v-col>
      <v-col cols="12" md="6">
        <LineChartWrapper
          v-if="loaded"
          title="Total Cases/Deaths"
          :population="population"
          :cases="history.cases"
          :deaths="history.deaths"
          :dates="history.dates"
          :loaded="loaded"
        />
        <v-row no-gutters class="mt-4">
          <v-col cols="6" class="text-center">
            <PopulationStats
              :items="cases"
              :population="population"
              label="cases / 1,000 people" />
          </v-col>
          <v-col cols="6" class="text-center">
            <PopulationStats
              :items="deaths"
              :population="population"
              label="deaths / 1,000 people" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <BarChartWrapper
          v-if="loaded"
          title="New Cases/Deaths per Day"
          :population="population"
          :cases="differences.cases"
          :deaths="differences.deaths"
          :dates="differences.dates"
          :loaded="loaded"
        />
        <v-row no-gutters class="mt-4">
          <v-col cols="6" class="text-center">
          </v-col>
        </v-row>
      </v-col>
      <v-divider></v-divider>
    </v-row>
    <ReportList :reports="orderedCounties"></ReportList>
  </div>
</template>

<script>
import { get } from 'axios';
import { mapMutations } from 'vuex';
import LineChartWrapper from '../components/LineChartWrapper.vue';
import BarChartWrapper from '../components/BarChartWrapper.vue';
import ReportList from '../components/ReportList.vue';
import PopulationStats from '../components/PopulationStats.vue';

export default {
  name: 'StateView',
  components: {
    LineChartWrapper,
    BarChartWrapper,
    ReportList,
    PopulationStats,
  },
  props: {
    state: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      name: null,
      fips: null,
      cases: null,
      deaths: null,
      date: null,
      population: null,
      history: null,
      differences: null,
      counties: null,
    };
  },
  computed: {
    orderedCounties() {
      if (!this.counties) return null;
      return [...this.counties].sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
  },
  methods: {
    async getState() {
      const filePath = `api/us/${this.urlify(this.state)}/index.json`;

      const { data } = await get(filePath);
      const {
        name,
        fips,
        cases,
        deaths,
        date,
        population,
        history,
        differences,
        counties,
      } = data;

      this.name = name;
      this.fips = fips;
      this.date = date;
      this.population = population;
      this.cases = cases;
      this.deaths = deaths;
      this.history = history;
      this.differences = differences;
      this.counties = counties;

      this.loaded = true;
    },
    urlify(str) { return str.replace(/[^a-z0-9]/gi, '_').toLowerCase(); },
    ...mapMutations([
      'updateTitle',
      'updateBackUrl',
    ]),
  },
  async mounted() {
    await this.getState();

    this.updateTitle(this.state);
    this.updateBackUrl('/');
  },
};
</script>

<style>
.county:hover {
  cursor: pointer;
}
</style>
