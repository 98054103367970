<template>
  <div id="app" >
    <v-app>
      <v-app-bar
        color="indigo darken-3"
        app
        elevate-on-scroll
        dark
      >
      <v-btn icon v-if="backUrl" :to="backUrl">
        <v-icon>{{ svgIcon }}</v-icon>
      </v-btn>
      <v-chip
        class="ma-2"
        color="indigo"
      >
        <img class="logo" alt="No Virus" src="./assets/virus.png">
        <v-toolbar-title>
          <span class="siteTitle d-none d-sm-flex">COVIDbyCounty.info</span>
        </v-toolbar-title>
      </v-chip>
      <span class="siteSubtitle d-flex d-sm-none text-no-wrap" v-if="title">
        {{title | unUrl | titleize}}
      </span>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer>
      <v-col class="text-center caption" cols="12">
        Case data collected by <a href="https://systems.jhu.edu/">Johns Hopkins Center for Systems Science and Engineering</a>
        and may be found <a href="https://github.com/CSSEGISandData/COVID-19">here</a>. Population data
        collected from the U.S. Census. Note that NYC population is a combination of several
        counties including Bronx, King, New York, Queens and Richmond Counties. Data
         is provided strictly for educational and academic research purposes. The creators
         of this website hereby disclaim any and all representations and warranties
         with respect to the Website, including accuracy, fitness for use, and merchantability.
         Reliance on the Website for medical guidance. Please note that this website is not
         affiliated with the data providers.
      </v-col>
    </v-footer>
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mdiArrowLeft } from '@mdi/js';

export default {
  name: 'App',
  data() {
    return {
      loading: false,
      drawer: false,
      svgIcon: mdiArrowLeft,
    };
  },
  computed: mapState(['title', 'backUrl']),
  created() {
    this.$vuetify.theme.dark = false;
  },
};
</script>

<style lang="scss">
  @import '../node_modules/typeface-roboto/index.css';
  @import '../node_modules/typeface-righteous/index.css';
  @import '../node_modules/typeface-oswald/index.css';

.siteTitle {
  margin-left: 0.5rem;
  font-family: 'Righteous', cursive;
}
.siteSubtitle {
  margin-left: 1rem;
  font-family: 'Oswald', sans-serif;
  font-size: 1.6rem;
}

body {
  font-family: 'Roboto', serif;
}
</style>
