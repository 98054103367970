<template>
  <v-list class="report-list">
    <v-list-item
      two-line
      v-for="report in reports"
      :key="report.fips"
      :to="report.url"
    >
      <v-list-item-content>
        <v-list-item-title class="title">{{report.name}}</v-list-item-title>
        <v-list-item-subtitle>Pop: {{ report.population | numeric('0.0a') }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div>
          <span class="title">{{ report.cases | numeric }}</span>
          <span class="caption font-weight-light text-uppercase">
            cases
          </span>
          <ChangeChip
            :before="report.history.cases[report.history.cases.length - 2]"
            :after="report.cases"
          />
        </div>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import ChangeChip from './ChangeChip.vue';

export default {
  name: 'ReportList',
  components: {
    ChangeChip,
  },
  props: {
    reports: {
      type: Array,
      default() { return []; },
    },
  },
};
</script>

<style>
.change-chip {
  display: inline-block;
}
</style>
