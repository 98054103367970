<template>
  <div class="stat-details">
    <v-row align="center">
      <v-col cols="12">
        <LineChart
          :chartdata="chartData"
          :options="chartOptions"
          :height="250"
        />
        <v-divider></v-divider>
      </v-col>
      <v-col cols="6" class="text-center">
        <Stat
          :value="cases[currentItemIndex]"
          label="cases"
          :before=cases[currentItemIndex-1]
          :after=cases[currentItemIndex]
        />
      </v-col>
      <v-col cols="6" class="text-center">
        <Stat
          :value="deaths[currentItemIndex]"
          label="deaths"
          :before=deaths[currentItemIndex-1]
          :after=deaths[currentItemIndex]
        />
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script>
import moment from 'moment';
import LineChart from './LineChart';
import Stat from './Stat.vue';

export default {
  name: 'LineChartWrapper',
  components: {
    LineChart,
    Stat,
  },
  props: [
    'title',
    'cases',
    'deaths',
    'dates',
    'loaded',
  ],
  data() {
    return {
      selectedItemIndex: null,
      chartStyles: {
        height: '200px',
        position: 'relative',
      },
      chartOptions: {
        responsive: true,
        title: {
          text: this.title,
          display: true,
        },
      },
    };
  },
  methods: {
    difference(prev, current) {
      const difference = current - prev;
      const pct = Math.floor((difference / prev) * 100) || 0;
      return {
        raw: difference,
        pct,
        icon: this.icon(pct),
        chipColor: this.chipColor(pct),
      };
    },
    chipColor(pct) {
      const modifier = Math.floor(pct / 20);
      let color;
      if (pct > 0) color = 'red';
      if (pct === 0) color = 'gray';
      if (pct < 0) color = 'green';
      return `${color} darken-${modifier}`;
    },
    icon(pct) {
      if (pct > 0) return 'mdi-arrow-up';
      if (pct === 0) return '';
      return 'mdi-arrow-down';
    },
    updateTotals(e) {
      if (!e) {
        this.selectedItemIndex = null;
        return;
      }
      this.selectedItemIndex = e.index;
    },
  },
  computed: {
    start() {
      return this.cases.findIndex((item) => item > 0);
    },
    chartCases() {
      const cases = [...this.cases];
      cases.splice(0, this.start);
      return cases;
    },
    chartDeaths() {
      const deaths = [...this.deaths];
      deaths.splice(0, this.start);
      return deaths;
    },
    chartDates() {
      const dates = [...this.dates];
      dates.splice(0, this.start);
      return dates;
    },
    currentItemIndex() {
      return this.selectedItemIndex || this.cases.length - 1;
    },
    prevItemIndex() {
      return this.currentItemIndex > 0 ? this.currentItemIndex - 1 : 0;
    },
    caseDifference() {
      return this.difference(
        this.cases[this.prevItemIndex], this.cases[this.currentItemIndex],
      );
    },
    deathDifference() {
      return this.difference(
        this.deaths[this.prevItemIndex], this.deaths[this.currentItemIndex],
      );
    },
    lastDate() {
      return this.dates[this.currentItemIndex];
    },
    chartLabels() {
      return this.chartDates.map((date) => moment(date).format('MMM D'));
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            label: 'Cases',
            backgroundColor: 'rgba(0, 150, 136, 0.05)',
            pointBackgroundColor: 'rgba(0, 150, 136, 1)',
            borderColor: 'rgba(0, 150, 136, 1)',
            pointBorderColor: 'rgba(0, 150, 136, 1)',
            data: this.chartCases,
          },
          {
            label: 'Deaths',
            backgroundColor: 'rgba(96, 125, 139, 0.05)',
            pointBackgroundColor: 'rgba(96, 125, 139, 1)',
            borderColor: 'rgba(96, 125, 139, 1)',
            pointBorderColor: 'rgba(96, 125, 139, 1)',
            data: this.chartDeaths,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .chart {
    position: relative;
    height: 200px;
    margin-bottom: 20px;
    cursor: crosshair;
  }
</style>
