<template>
  <div class="stat">
    <div>
      <div class="display-1">{{ formatNumber(value) }}</div>
      <div class="font-weight-light caption text-uppercase">
        {{ label }}
      </div>
    </div>
    <div>
      <ChangeChip
        :before="before"
        :after="after"
      />
    </div>
  </div>

</template>

<script>
import ChangeChip from './ChangeChip.vue';

export default {
  name: 'Stat',
  components: {
    ChangeChip,
  },
  props: [
    'value',
    'label',
    'before',
    'after',
  ],
  methods: {
    formatNumber: (num) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
  },
};
</script>

<style>
</style>
